import Header from "@/components/Header/Header.vue";
import Footer from "@/components/Footer/Footer.vue";
export default {
	name: "App",
	created () {
		this.$nextTick(function () {
			// localStorage.clear()
			if (this.$route.path == '/mibb/' || this.$route.path == '/mibb') {
				this.$router.push('/mibb/Home')
			}


		});
	},
	mounted () {
		this.$nextTick(function () {
			// 监听屏幕
			if ($(window).width() <= 992) {
				// Header导航栏添加点击事件
				$('#header').find('.navbar-nav').addClass('mobile')
				//  Footer导航栏添加点击事件
				$('#Footer').find('.nav_box').addClass('mobile')
			} else {
				// Header导航栏去除点击事件
				$('#header').find('.navbar-nav').removeClass('mobile')
				// Header导航栏移除内联样式
				$('#header').find('.navbar-nav').find('.dropdown-menu').removeAttr('style')
				//  Footer导航栏去除点击事件
				$('#Footer').find('.nav_box').removeClass('mobile')
				// Footer导航栏移除内联样式
				$('#Footer').find('.nav_box').find('.dropdown > ul').removeAttr('style')
			}
			$(window).resize(function () {
				if ($(window).width() <= 992) {
					// Header导航栏添加点击事件
					$('#header').find('.navbar-nav').addClass('mobile')
					//  Footer导航栏添加点击事件
					$('#Footer').find('.nav_box').addClass('mobile')
				} else {
					// Header导航栏去除点击事件
					$('#header').find('.navbar-nav').removeClass('mobile')
					// Header导航栏移除内联样式
					$('#header').find('.navbar-nav').find('.dropdown-menu').removeAttr('style')
					//  Footer导航栏去除点击事件
					$('#Footer').find('.nav_box').removeClass('mobile')
					// Footer导航栏移除内联样式
					$('#Footer').find('.nav_box').find('.dropdown > ul').removeAttr('style')
				}
			})

		})
	},
	provide () {
		//父组件中通过provide来提供变量，在子组件中通过inject来注入变量。
		return {
			relode: this.relode,
		};
	},
	data () {
		return {
			headerLogoBlack: require('../../public/image/header_logo_black.png'), // Header导航栏logo 动画之后
			headerLogoWhite: require('../../public/image/header_logo_white.png'), // Header导航栏logo 动画之前
			thisPageSite: null, // 当前页面的url地址
			routerF: true
		};
	},
	watch: {
		$route: {
			handler: 'routeCue',
			// 深度观察监听
			deep: true
		}
	},
	methods: {
		SomehowThePromptModalClose: function (name) {
			console.log(name);
			localStorage.setItem(name, true);
		},
		relode: function () {
			this.routerF = false
			this.$nextTick(() => {
				this.routerF = true
			})
		},
		routeCue: function (val, oldVal) {
			if (val.meta.title) {
				document.title = val.meta.title
			}
			if (val.meta.content) {
				let head = document.getElementsByTagName('head');
				let meta = document.createElement('meta');
				document.querySelector('meta[name="keywords"]').setAttribute('content', val.meta.content.keywords)
				document.querySelector('meta[name="description"]').setAttribute('content', val.meta.content.description)
				meta.content = val.meta.content;
				head[0].appendChild(meta)
			}
			let _this = this
			// 接受当前页面的url地址
			if (val.meta.name) {
				this.thisPageSite = this.$t(val.meta.name).toUpperCase()
			}


			// 不是首页的导航栏的css
			if (this.thisPageSite == this.$t('nav.H').toUpperCase()) {
				$('#header').find(".navbar-nav").css({
					left: '100%'
				})
				if ($(document).scrollTop() == 0) {
					$('#header').css({
						position: 'relative'
					})
				} else {
					$('#header').css({
						position: 'fixed'
					})
				}
				// 监听滚动条
				$(window).scroll(function () {
					if ($(document).scrollTop() == 0) {
						$('#header').css({
							position: 'relative'
						})
					} else {
						$('#header').css({
							position: 'fixed'
						})
					}
				});
			} else {
				$('#header').css({
					position: 'relative',
					left: '0'
				})
				$(window).unbind('scroll')
			}
			$('#mobile_navbar').find('.mobile_navbar-nav').animate({
				width: '0'
			}, 500)
			setTimeout(function () {
				$('#mobile_navbar').hide()
			}, 510);
			$('#mobile_navbar').find('.dropdown-toggle').parent().nextAll().slideUp()
			// $('#header').find(".navbar-nav").removeClass('navbar-nav_animation')

			if (this.thisPageSite == this.$t('nav.WS').toUpperCase() || this.thisPageSite == this.$t('nav.WC').toUpperCase() || this.thisPageSite == this.$t('nav.WTe').toUpperCase() || this.thisPageSite == this.$t('nav.TC').toUpperCase()) {
				window.addEventListener("orientationchange", function () {
					if (Math.abs(window.orientation) !== 90) {
						if (localStorage.getItem(this.$t('nav.WS').toUpperCase()) == null && _this.thisPageSite == this.$t('nav.WS').toUpperCase()) {
							$('#SomehowThePromptModal').modal('show')
						}
						if (localStorage.getItem(this.$t('nav.WC').toUpperCase()) == null && _this.thisPageSite == this.$t('nav.WC').toUpperCase()) {
							$('#SomehowThePromptModal').modal('show')
						}
						if (localStorage.getItem(this.$t('nav.WTe').toUpperCase()) == null && _this.thisPageSite == this.$t('nav.WTe').toUpperCase()) {
							$('#SomehowThePromptModal').modal('show')
						}
						if (localStorage.getItem(this.$t('nav.TC').toUpperCase()) == null && _this.thisPageSite == this.$t('nav.TC').toUpperCase()) {
							$('#SomehowThePromptModal').modal('show')
						}
					}
				}, false)
			}
			this.relode()
		},

	},
	components: {
		Header,
		Footer
	}
};