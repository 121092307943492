export default {
    name: 'Footer',
    created () {
        this.$nextTick(function () {
            // 导航栏内容去开头两个
            this.navbarReverse()
        })
    },
    mounted () {

    },
    props: ['thisPageSite'],
    data () {
        return {
            navigation: [], // 导航栏数组
            logoData: [{
                Url: 'https://www.bnm.gov.my/',
                Img: require('../../../public/image/footer_logo_0.png'),
                Width: '25px',
                Height: '30px'
            },
            {
                Url: 'http://www.bankinginfo.com.my/',
                Img: require('../../../public/image/footer_logo_1.png'),
                Width: '58px',
                Height: '22px'
            },
            {
                Url: 'http://www.insuranceinfo.com.my/',
                Img: require('../../../public/image/footer_logo_2.png'),
                Width: '59px',
                Height: '24px'
            },
            {
                Url: 'https://www.ofs.org.my/en/',
                Img: require('../../../public/image/footer_logo_3.png'),
                Width: '57px',
                Height: '26px'
            },
            {
                Url: 'http://www.smeinfo.com.my/',
                Img: require('../../../public/image/footer_logo_4.png'),
                Width: '53px',
                Height: '13px'
            },
            {
                Url: 'http://www.pidm.gov.my/',
                Img: require('../../../public/image/footer_logo_5.png'),
                Width: '45px',
                Height: '33px'
            },
            {
                Url: 'https://www.akpk.org.my/',
                Img: require('../../../public/image/footer_logo_6.png'),
                Width: '40px',
                Height: '19px'
            },
            {
                Url: 'http://www.mifc.com/',
                Img: require('../../../public/image/footer_logo_7.png'),
                Width: '47px',
                Height: '17px'
            },
            {
                Url: 'http://www.ibfim.com/',
                Img: require('../../../public/image/footer_logo_8.png'),
                Width: '45px',
                Height: '33px'
            },
            {
                Url: 'https://mybayar.gov.my/ms/registration/frmlogins.aspx',
                Img: require('../../../public/image/footer_logo_9.png'),
                Width: '52px',
                Height: '13px'
            },
            {
                Url: 'http://www.inceif.org/',
                Img: require('../../../public/image/footer_logo_10.png'),
                Width: '50px',
                Height: '14px'
            },
            {
                Url: 'https://iaplatform.com/',
                Img: require('../../../public/image/footer_logo_11.png'),
                Width: '79px',
                Height: '28px'
            },
            {
                Url: 'http://www.meps.com.my/',
                Img: require('../../../public/image/footer_logo_12.png'),
                Width: '40px',
                Height: '20px'
            },
            {
                Url: 'http://www.mepsatm.com.my/',
                Img: require('../../../public/image/footer_logo_13.png'),
                Width: '48px',
                Height: '13px'
            },
            {
                Url: 'http://www.housingwatch.my/',
                Img: require('../../../public/image/footer_logo_14.png'),
                Width: '52px',
                Height: '26px'
            },
            {
                Url: 'https://www.mywakaf.com.my/',
                Img: require('../../../public/image/footer_logo_15.png'),
                Width: '45px',
                Height: '35px'
            },
            {
                Url: 'https://portal.3sisecure.com/images/pcs/52D0C14D-2365-4EAF-9875-0C3C6302CC80.html',
                Img: require('../../../public/image/footer_logo_16.png'),
                Width: '110px',
                Height: '18px'
            }
            ],
            Year: new Date().getFullYear()
        }
    },
    computed: {

    },
    watch: {

    },
    methods: {
        // 接受导航栏内容去开头两个
        navbarReverse: function () {
            const _this = this
            $.each(this.$router.options.routes, function (index, routes) {
                if (index > 1) {
                    _this.navigation.push(routes)
                }
            })
        },
        // 移动端导航栏点击出二级导航
        outMoversNavbar: function (index) {
            if (index == 4) {
                index = index - 1
            }
            $('#Footer').find('.mobile').find('.dropdown-toggle').eq(index).nextAll().slideToggle()
        }
    }
}
