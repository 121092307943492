import Vue from 'vue'
import VueRouter from 'vue-router'
import $$ from "../assets/js/global.js";
let urlArr = []
const Margin = () =>
	import('@/views/Education/views/Margin/Margin.vue')
$.ajax({
	type: "GET",
	url: $$.mibb + "GetEducationContent",
	data: { id: "", lang: localStorage.getItem('lang') || 1 },
	dataType: "json",
	async: false,
	success: (data) => {
		$.each(data.text, (i, c) => {
			if (c.title) {
				urlArr.push({
					component: Margin,
					path: '/mibb/Education/' + c.title.replace(/\s*/g, ""),
					name: c.title,
					meta: {
						// 判断是否有三级导航栏
						ViewsNav: false,
						id: c.id,
						title: c.title + ' - Education | Maybank Warrants',
						// img: require(`../../public/image/EducationIndex_${i + 3}.jpg`),
						img: c.image ? c.image : 'https://warrants.maybank2u.com.my/mibbresources/assets/Education 1.jpg',
						content: {}
					}
				})
			}

		})
	},
	error: function (error) {
		console.log(error);
	}
});

// LandingPage
const Home = () =>
	import('../views/Home/Home.vue')

// Warrant tools
const WarrantTools = () =>
	import('@/views/WarrantTools/WarrantTools.vue')
// Views
const LiveMatrix = () =>
	import('@/views/WarrantTools/views/LiveMatrix/LiveMatrix.vue')
const WarrantSearch = () =>
	import('@/views/WarrantTools/views/WarrantSearch/WarrantSearch.vue')
const WarrantTerms = () =>
	import('@/views/WarrantTools/views/WarrantTerms/WarrantTerms.vue')
const WarrantComparison = () =>
	import('@/views/WarrantTools/views/WarrantComparison/WarrantComparison.vue')
const WarrantCalculator = () =>
	import('@/views/WarrantTools/views/WarrantCalculator/WarrantCalculator.vue')
const StructureYourWarrant = () =>
	import('@/views/WarrantTools/views/StructureYourWarrant/StructureYourWarrant.vue')
const Underlying = () =>
	import('@/views/WarrantTools/views/Underlying/Underlying.vue')

// Observations
const Observations = () =>
	import('@/views/Observations/Observations.vue')
// Views
const TopTradedShares = () =>
	import('@/views/Observations/views/TopTradedShares/TopTradedShares.vue')
const WarrantMovers = () =>
	import('@/views/Observations/views/WarrantMovers/WarrantMovers.vue')
const DailyHighlights = () =>
	import('@/views/Observations/views/DailyHighlights/DailyHighlights.vue')
const WarrantWatch = () =>
	import('@/views/Observations/views/WarrantWatch/WarrantWatch.vue')
const WarrantsBulletin = () =>
	import('@/views/Observations/views/WarrantsBulletin/WarrantsBulletin.vue')

// Warrants Documentations
const Documents = () =>
	import('@/views/Documents/Documents.vue')
// Views
const BaseProspectus = () =>
	import('@/views/Documents/views/BaseProspectus/BaseProspectus.vue')
const TermSheet = () =>
	import('@/views/Documents/views/TermSheet/TermSheet.vue')
const WarrantsAnnouncement = () =>
	import('@/views/Documents/views/WarrantsAnnouncement/WarrantsAnnouncement.vue')
const UnderlyingReports = () =>
	import('@/views/Documents/views/UnderlyingReports/UnderlyingReports.vue')

// Education
const Education = () =>
	import('@/views/Education/Education.vue')
const EducationIndex = () =>
	import('@/views/Education/views/Index/index.vue')
// Views
const Glossary = () =>
	import('@/views/Education/views/Glossary/Glossary.vue')
const DecksAndSlides = () =>
	import('@/views/Education/views/DecksAndSlides/DecksAndSlides.vue')
const Infographics = () =>
	import('@/views/Education/views/Infographics/Infographics.vue')
const Quiz = () =>
	import('@/views/Education/views/Quiz/Quiz.vue')
const WarrantsKnowItAll = () =>
	import('@/views/Education/views/WarrantsKnowItAll/WarrantsKnowItAll.vue')


// About Maybank Investment Bank
const AboutUs = () =>
	import('@/views/AboutUs/AboutUs.vue')
// Views
const SonAboutUs = () =>
	import('@/views/AboutUs/views/SonAboutUs/SonAboutUs.vue')
const ContactUs = () =>
	import('@/views/AboutUs/views/ContactUs/ContactUs.vue')
const Disclaimer = () =>
	import('@/views/AboutUs/views/Disclaimer/Disclaimer.vue')

Vue.use(VueRouter)

const routes = [
	// 路由重定向
	{
		path: '/',
		redirect: '/mibb/Home',
		hidden: true,
		meta: {
			title: 'Home | Maybank Warrants',
			content: {
				keywords: 'Malaysia Warrants, Maybank Structured Warrants, Malaysia Top Issuer, Malaysia Top Warrants, Structured Warrants, Daily Highlights.',
				description: 'Maybank Warrants – The only warrants website you need. Conduct analysis with Warrant Tools. Get information and market news on Maybank or any issued warrants in Malaysia, or simply contact the Maybank Warrants Team for any questions.'
			}
		}
	},
	// LandingPage
	{
		path: '/mibb/Home',
		name: 'Home',
		component: Home,
		meta: {
			name: 'nav.H',
			title: 'Maybank Warrants | The Only Warrants Website You Need',
			content: {
				keywords: 'Malaysia Warrants, Maybank Structured Warrants, Malaysia Top Issuer, Malaysia Top Warrants, Structured Warrants, Daily Highlights.',
				description: 'Maybank Warrants – The only warrants website you need. Conduct analysis with Warrant Tools. Get information and market news on Maybank or any issued warrants in Malaysia, or simply contact the Maybank Warrants Team for any questions.'
			}
		}
	},

	// Warrant tools
	{
		path: '/mibb/WarrantTools',
		name: 'Warrant tools',
		component: WarrantTools,
		meta: {
			name: 'nav.WT',
		},
		// Views
		children: [{
			path: '/mibb/WarrantTools/LiveMatrix',
			name: 'Live Matrix',
			component: LiveMatrix,
			meta: {
				// 判断是否有三级导航栏
				ViewsNav: false,
				name: 'nav.LM',
				title: 'Live Matrix - Warrant Tools | Maybank Warrants',
				content: {
					keywords: 'Live Matrix, Bid Price,',
					description: 'Review the indicative bid and offer prices for warrants listed by Maybank IB, based on various price points in the underlying (stock or index).'
				}
			}
		}, {
			path: '/mibb/WarrantTools/WarrantSearch',
			name: 'Warrant Search',
			component: WarrantSearch,
			meta: {
				// 判断是否有三级导航栏
				ViewsNav: false,
				name: 'nav.WS',
				title: 'Warrant Search - Warrant Tools | Maybank Warrants',
				content: {
					keywords: 'Warrant Search, Trending Warrants, New Warrants, Sensitive Warrants, High Liquidity.',
					description: 'Search for any warrants of your choice'
				}
			}
		}, {
			path: '/mibb/WarrantTools/WarrantTerms',
			name: 'Warrant Terms',
			component: WarrantTerms,
			meta: {
				// 判断是否有三级导航栏
				ViewsNav: false,
				name: 'nav.WTe',
				title: 'Warrant Terms - Warrant Tools | Maybank Warrants',
				content: {
					keywords: 'Warrant Terms, Warrant Chart ',
					description: 'Compare Warrant Terms of several warrants simultaneously and its key indicators for any warrants listed on Bursa Malaysia.'
				}
			}
		}, {
			path: '/mibb/WarrantTools/WarrantComparison',
			name: 'Warrant Comparison',
			component: WarrantComparison,
			meta: {
				// 判断是否有三级导航栏
				ViewsNav: false,
				name: 'nav.WC',
				title: 'Warrant Comparison - Warrant Tools | Maybank Warrants',
				content: {
					keywords: 'Warrants Comparison',
					description: 'Compare warrants of the same underlying with their key factors displayed side by side for easy viewing.'
				}
			}
		}, {
			path: '/mibb/WarrantTools/WarrantCalculator',
			name: 'Warrant Calculator',
			component: WarrantCalculator,
			meta: {
				// 判断是否有三级导航栏
				ViewsNav: false,
				name: 'nav.WCa',
				title: 'Warrant Calculator - Warrant Tools | Maybank Warrants',
				content: {
					keywords: 'Warrants Calculator, Warrant Settlement, Warrant Price',
					description: 'Warrant Calculator helps investors to gauge the theoretical price of a warrant.'
				}
			}
		}, {
			path: '/mibb/WarrantTools/StructureYourWarrant',
			name: 'Structure Your Warrant',
			component: StructureYourWarrant,
			meta: {
				// 判断是否有三级导航栏
				ViewsNav: false,
				name: 'nav.SYW',
				title: 'Structure Your Warrant - Warrant Tools | Maybank Warrants',
				content: {
					keywords: 'Warrant Suggestion, Warrant Recommendation, Maybank Issued Warrants, Strcuture Your Warrants,',
					description: 'This feedback mechanism provides an avenue to submit suggested warrants that investors are interested in.'
				}
			}
		},
		{
			path: '/mibb/WarrantTools/TechnicalChart',
			name: 'Technical Chart',
			component: Underlying,
			meta: {
				// 判断是否有三级导航栏
				ViewsNav: false,
				name: 'nav.TC',
				title: 'Technical Chart - Warrant Tools | Maybank Warrants',
				content: {
					keywords: `Technical Chart, Technical Analysis `,
					description: `Perform technical analysis on the underlying charts to study the direction of the warrant's underlying.`
				}
			}
		}
		]
	},
	// Observations 
	{
		path: '/mibb/Observations',
		name: 'Observations',
		component: Observations,
		meta: {
			name: 'nav.Ob',
		},
		// Views
		children: [{
			path: '/mibb/Observations/TopTradedShares',
			name: 'Top Traded Shares',
			component: TopTradedShares,
			meta: {
				// 判断是否有三级导航栏
				ViewsNav: false,
				name: 'nav.TTS',
				title: 'Top Traded Shares - Observations | Maybank Warrants',
				content: {
					keywords: 'Malaysia Top Traded Shares,',
					description: 'Top 10 underlying shares ranked by volume with selected corresponding Maybank IB issued warrants.'
				}
			}
		}, {
			path: '/mibb/Observations/WarrantMovers',
			name: 'Warrant Movers',
			component: WarrantMovers,
			meta: {
				// 判断是否有三级导航栏
				ViewsNav: false,
				name: 'nav.WM',
				title: 'Warrant Movers - Observations | Maybank Warrants',
				content: {
					keywords: 'Top Traded Warrants, Top Gainer Warrants. ',
					description: 'Top 10 ranking of Malaysian warrants by category.'
				}
			}
		}, {
			path: '/mibb/Observations/WarrantsWatch',
			name: 'Warrants Watch',
			component: WarrantWatch,
			meta: {
				// 判断是否有三级导航栏
				ViewsNav: false,
				name: 'nav.WW',
				title: 'Warrants Watch - Observations | Maybank Warrants',
				content: {
					keywords: 'Warrant Information, Daily Highlights, Warrant News, Sector Warrants Write Up, Warrant Suggestion, Warrant Recommendation,',
					description: 'Maybank Insights on selected warrants.'
				}
			}
		},
		// {
		//     path: 'https://telegram.org/',
		//     name: 'Telegram Live',
		//     meta: {
		//         // 判断是否有三级导航栏
		//         ViewsNav: false,
		//         title: 'Telegram Live - Observations | Maybank Warrants',
		//         content: {}
		//     }
		// },
		{
			path: '/mibb/Observations/WarrantsBulletin',
			name: 'Warrants Bulletin',
			component: WarrantsBulletin,
			meta: {
				// 判断是否有三级导航栏
				ViewsNav: false,
				name: 'nav.WB',
				title: 'Warrants Bulletin - Observations | Maybank Warrants',
				content: {
					keywords: 'Structured Warrants news and updates. ',
					description: 'Warrant Information, Daily Highlights, Warrant News, Expiring Warrants, Warrants To Invest'
				}
			}
		},
		]
	},
	// Warrants Documentations
	{
		path: '/mibb/Documents',
		name: 'Documents',
		component: Documents,
		meta: {
			name: 'nav.Do',
		},
		// Views
		children: [{
			path: '/mibb/Documents/BaseProspectus',
			name: 'Base Prospectus',
			component: BaseProspectus,
			meta: {
				// 判断是否有三级导航栏
				ViewsNav: false,
				name: 'nav.BP',
				title: 'Base Prospectus - Documents | Maybank Warrants',
				content: {
					keywords: 'Maybank Warrants Base Prospectus',
					description: 'It is advised that you should review these documents before investing.'
				}
			}
		},
		{
			path: '/mibb/Documents/TermSheet',
			name: 'Term Sheet',
			component: TermSheet,
			meta: {
				// 判断是否有三级导航栏
				ViewsNav: false,
				name: 'nav.TS',
				title: 'Term Sheet - Documents | Maybank Warrants',
				content: {
					keywords: 'Maybank Warrants Term Sheet, Maybank Documents',
					description: 'It is advised that you should review these documents before investing.'
				}
			}
		},
		{
			path: '/mibb/Documents/WarrantsAnnouncement',
			name: 'Warrants Announcement',
			component: WarrantsAnnouncement,
			meta: {
				name: 'nav.WA',
				title: 'Warrants Announcement - Documents | Maybank Warrants',
				content: {
					keywords: 'Warrants Announcement',
					description: 'Announcements relating to Maybank issued warrants including corporate action adjustments. '
				}
			}
		},

			// {
			//   path: '/mibb/Documents/UnderlyingReports',
			//   name: 'Underlying Reports',
			//   component: UnderlyingReports,
			//   meta: {
			//     // 判断是否有三级导航栏
			//     ViewsNav: false,
			//     title: '',
			//     content: {}
			//   }
			// }
		]
	},
	// Education
	{
		path: '/mibb/Education',
		name: 'Education',
		component: Education,
		meta: {
			name: 'nav.Ed',
		},
		// Views
		children: [
			{
				path: '/mibb/Education/Education',
				name: 'EducationIndex',
				component: EducationIndex,
				meta: {
					// 判断是否有三级导航栏
					ViewsNav: false,
					title: 'index - Education | Maybank Warrants',
					content: {
						keywords: '',
						description: ''
					}
				}
			},
			{
				path: '/mibb/Education/Glossary',
				name: 'Glossary',
				component: Glossary,
				meta: {
					// 判断是否有三级导航栏
					ViewsNav: false,
					name: 'nav.Gl',
					title: 'Glossary - Education | Maybank Warrants',
					img: require('../../public/image/EducationIndex_2.png'),
					description: 'Gly.Sitywt',
					content: {
						keywords: 'Warrant Terminologies',
						description: 'Collection of investing terminologies you may come across in warrant trading'
					}
				}
			},
			{
				path: '/mibb/Education/DecksAndSlides',
				name: 'Decks and Slides',
				component: DecksAndSlides,
				meta: {
					// 判断是否有三级导航栏
					ViewsNav: false,
					name: 'nav.DaS',
					title: 'Decks and Slides - Education | Maybank Warrants',
					img: require('../../public/image/EducationIndex_3.png'),
					description: 'DASs.DweWW',
					content: {
						keywords: 'Maybank Webinar Decks, Maybank Webinar Presentation',
						description: 'Download decks from webinars or information relating to Warrants Trading and the Maybank Warrants Website'
					}
				}
			},
			{
				path: '/mibb/Education/Infographics',
				name: 'Infographics',
				component: Infographics,
				meta: {
					// 判断是否有三级导航栏
					ViewsNav: false,
					name: 'nav.Infog',
					title: 'Infographics - Education | Maybank Warrants',
					img: require('../../public/image/EducationIndex_5.png'),
					description: 'Infogs.Bitw',
					content: {
						keywords: '',
						description: ''
					}
				}
			},
			// {
			// 	path: '/mibb/Education/WarrantsKnowItAll',
			// 	name: 'WarrantsKnowItAll',
			// 	component: WarrantsKnowItAll,
			// 	meta: {
			// 		// 判断是否有三级导航栏
			// 		ViewsNav: false,
			// 		name: 'nav.WarrantsKnowItAll',
			// 		title: 'WarrantsKnowItAll - Education | Maybank Warrants',
			// 		img: require('../../public/image/EducationIndex_4.png'),
			// 		description: 'WKItA.description',
			// 		content: {
			// 			keywords: '',
			// 			id: '',
			// 			description: ''
			// 		}
			// 	}
			// },
			...urlArr,

			{
				path: '/mibb/Education/Quiz',
				name: 'Quiz',
				component: Quiz,
				meta: {
					// 判断是否有三级导航栏
					ViewsNav: false,
					name: 'nav.Quiz',
					title: 'Quiz - Education | Maybank Warrants',
					img: require('../../public/image/EducationIndex_4.png'),
					description: 'DASs.TYSWK',
					content: {
						keywords: '',
						id: '',
						description: ''
					}
				}
			},
		]
	},
	// About Maybank Investment Bank
	{
		path: '/mibb/AboutUs',
		name: 'About Us',
		component: AboutUs,
		meta: {
			name: 'nav.AU',
		},
		// Views
		children: [{
			path: '/mibb/AboutUs/AboutUs',
			name: 'Son About Us',
			component: SonAboutUs,
			meta: {
				name: 'nav.AU',
				title: 'About Us - About Us | Maybank Warrants',
				content: {
					keywords: '',
					description: 'History and background of Maybank Investment Bank'
				}
			}
		}, {
			path: '/mibb/AboutUs/ContactUs',
			name: 'Contact Us',
			component: ContactUs,
			meta: {
				name: 'nav.CU',
				title: 'Contact Us - About Us | Maybank Warrants',
				content: {
					keywords: '',
					description: ''
				}
			}
		}, {
			path: '/mibb/AboutUs/Disclaimer',
			name: 'Disclaimer',
			component: Disclaimer,
			meta: {
				name: 'nav.Di',
				title: 'Disclaimer Us - About Us | Maybank Warrants',
				content: {
					keywords: '',
					description: 'Disclaimer and terms and conditions to use the Maybank Warrants Website.'
				}
			}
		}]
	}
]

// 解决重复触发了同一个路由
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
	return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router